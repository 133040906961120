import logo from "./logo.svg";
import "./App.css";
import Home from "./Pages/Home/Home";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import RouteConfig from "./Routes/RouteConfig";

let fetching = false;
function App() {
  const dispatch = useDispatch();
  const getSiteData = async () => {
    const blogs = await axios
      .get("https://www.addensure.com/addensureapi/Blog/Blog.php")
      .then((result) => {
        console.log(result.data)
        dispatch({
          type: "blogs",
          payload: result.data,
        });
      });
    return blogs;
  };
  useEffect(() => {
    if (!fetching) getSiteData();
  }, [fetching]);
  return <RouteConfig />;
}

export default App;
