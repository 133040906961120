import React, { useEffect, useRef } from "react";
import Header from "../../Component/Common/Header";
import { imageLinks1, imageLinks2 } from "../../Data/DataArray";
import { useTypewriter } from "react-simple-typewriter";
import Services from "../../Component/Home/Services";
import CompanyInfo from "../../Component/Home/CompanyInfo";
import Person from "../../Assets/images/person.jpeg";
import { motion } from "framer-motion";
import Footer from "../../Component/Common/Footer";
import Testmonial from "../../Component/Home/Testmonial";
import HomeBanner from "../../Component/Home/HomeBanner";
import OurClient from "../../Component/Home/OurClient";
import { useNavigate } from "react-router";
import Blogs from "../../Component/Home/Blogs";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { loader } from "../Blog/BlogLoader";

function Home() {
  const navigate = useNavigate();
  let query = new URLSearchParams(window.location.search).get("ref");
  useEffect(() => {
    if (query) {
      setTimeout(() => {
        let scrollDownTo = document.getElementsByClassName("services")[0];
        scrollDownTo.scrollIntoView({
          behavior: "smooth",
        });
        setTimeout(() => {
          navigate("/");
        }, 800);
      }, 7500);
    }
  }, [query]);

  return (
    <>
      <Helmet>
        <title>Addensure | CPA , CPS , CPI Affiliate Marketing Company.</title>
        <link rel="canonical" href="http://www.addensure.com" />
        <meta
          name="description"
          content="Best Digital Marketing Agency, that provides email marketing, SMS marketing, SEO, SMM, Website Solutions, Media Planning & Buying"
        />
      </Helmet>
      <motion.div
        className="w-full bg-[black] overflow-hidden"
        initial={{
          overflow: "hidden",
          height: "100vh",
        }}
        transition={{
          delay: 4,
        }}
        animate={{
          height: "auto",
          overflow: "auto",
        }}
      >
        <Header />
        <HomeBanner />
        <OurClient />
        {/* <CompanyInfo /> */}
        <Services />
        <motion.div className="h-[500px] w-full  pt-28 flex flex-col items-center relative overflow-hidden max-[735px]:h-auto bg-[#cccccc]">
          <motion.div
            initial={{
              scale: 0.8,
            }}
            transition={{
              duration: 0.6,
              delay: 0.6,
            }}
            whileInView={{
              scale: 1,
            }}
            viewport={{
              once: true,
            }}
            className="absolute -bottom-96 max-[500px]:-bottom-[0] w-[105vw] h-[850px] rounded-t-full -z-0 max-[735px]:h-[1000px] max-sm:h-[900px]"
          >
            <div className=" bg-gradient-to-b p-0.5 max-[500px]:rounded-t-none from-black/70 rounded-t-full to-black/70 z-50 w-full top-0 left-0 right-0 h-full absolute"></div>
            <div className="w-full h-full absolute">
              <img
                className="w-full h-full object-cover object-top rounded-t-full relative top-0 right-0 bottom-0 z-5 left-0"
                src="https://images.pexels.com/photos/2041627/pexels-photo-2041627.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                loading="lazy"
                alt="site_image "
              />
            </div>
          </motion.div>
          <div className="flex items-center justify-center z-10 max-[735px]:mt-20 text-white max-[500px]:mt-0">
            <div className="text-4xl text-center mx-14 font-bold max-[735px]:mx-8 max-[510px]:px-4 max-[510px]:text-3xl">
              4 cr
              <div className="text-base font-normal">Clicks</div>
            </div>
            <div className="text-4xl text-center font-bold mx-14 max-[735px]:mx-8 max-[510px]:px-4 max-[510px]:text-3xl">
              2.5 Lakh
              <div className="text-base font-normal">Leads</div>
            </div>
            <div className="text-4xl text-center mx-14 font-bold max-[735px]:mx-8 max-[510px]:px-4 max-[510px]:text-3xl">
              30+
              <div className="text-base font-normal">Clients</div>
            </div>
          </div>
          <div className="w-8/12 pt-12 h-full z-10 max-sm:w-11/12 relative text-white max-[731px]:pb-16 max-[731px]:w-11/12">
            <div className="mt-4 text-2xl max-sm:text-base">
              We believe Digital marketing is defined by the use of multiple
              channels strategically to connect with consumers to increase the
              Brand awareness and conversation ratio.
            </div>
            <div className="z-10 mt-4 flex items-center justify-start">
              <Link to="/in/contactus">
                {" "}
                <div className="rounded-md btn-grad  bg-gradient-to-r p-0.5 from-green-400 via-yellow-500 to-green-400 text-white">
                  {" "}
                  <div className=" px-7 bg-[#052425] font-bold rounded-md border border-white py-2">
                    Contact Us
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </motion.div>

        <Blogs />
        <div className="my-8"></div>
        <Testmonial />
        <div className="py-20 px-16 max-sm:px-10 max-sm:py-16">
          <div className="flex items-center flex-col text-slate-300">
            <div className="font-semibold text-7xl text-center text-white w-11/12 max-sm:text-2xl max-sm:w-full relative">
              <span className="text-[#7ab52e] text-6xl max-sm:hidden absolute top-0 left-6">
                "
              </span>
              Speak to your audience in their language about what's in their
              hearts.
              <span className="text-[#7ab52e] text-6xl max-sm:hidden">"</span>
            </div>
            <div className="mt-4">
              {" "}
              <div className="flex items-center justify-end my-4 px-8">
                <img
                  src={Person}
                  alt="addensure_founder"
                  className="w-11 h-11 object-contain rounded-full"
                />
                <div className="ml-3 text-lg flex justify-center flex-col">
                  <h1 className="font-bold">Jonathan Lister</h1>
                  <span className="text-xs text-white/70">Vidyard COO</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </motion.div>
    </>
  );
}

export default Home;
