import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { useSelector } from "react-redux";
import { blogs } from "../../Data/DataArray";

function Blogs() {
  return (
    <div className="w-full py-20 px-20  flex items-start flex-col bg-white max-md:px-8 max-sm:px-2">
      <div className="flex items-start flex-col">
        <div className="py-2 px-6 rounded-full border border-black text-black text-sm">
          Blogs
        </div>
        <div className="text-7xl mt-4 w-9/12 text-black font-bold max-md:text-4xl max-sm:text-2xl">
          Latest blogs from our team
        </div>
      </div>
      <div className="pt-16 w-full max-md:pt-8">
        <div className="flex items-center flex-wrap w-full">
          {blogs.map((post) => (
            <motion.div
              onClick={() => {
                window.location.assign(
                  `/in/post/${post.blog_title.split(" ").join("-")}?id=${
                    post.blog_id
                  }`
                );
              }}
              className="w-1/2 h-96 p-2 max-md:w-full max-[1270px]:h-auto max-lg:min-h-0 cursor-pointer"
              initial={{
                opacity: 0,
                scale: 0.9,
              }}
              transition={{
                duration: 0.3,
                delay: 0.3,
                type: "tween",
              }}
              whileInView={{
                opacity: 1,
                scale: 1,
              }}
            >
              <div
                className="w-full border-black/20 rounded-md border h-full relative flex  flex-col"
                // style={{
                //   background: `linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%,  rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 1) 100%), url(https://images.pexels.com/photos/267350/pexels-photo-267350.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2) center/cover no-repeat`,
                // }}
              >
                <div className="h-3/5 w-full max-lg:h-72 max-md:h-60 max-sm:h-44">
                  <img
                    className="w-full h-full object-cover"
                    src={`https://www.addensure.com/addensureapi/Blog/images/${post?.blog_image}`}
                    alt="blog_image"
                    loading="lazy"
                  />
                </div>
                <div className="py-4 px-7 h-2/5 flex items-start justify-start flex-col">
                  <div className="text-lg font-bold text-black relative z-10">
                    {post?.blog_title}
                  </div>
                  <div className="flex text-base text-white  items-start justify-start bg-black border mt-5 border-white/60 py-2 px-7 rounded-full">
                    Read article
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>{" "}
        {/* <motion.div className="w-full h-[450px]">
          <div
            className="w-full h-full relative flex items-start py-10 px-20 justify-end flex-col"
            style={{
              background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 1)), url(https://images.pexels.com/photos/267350/pexels-photo-267350.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2) center/cover no-repeat`,
            }}
          >
            <div className="text-4xl font-bold text-white relative z-10">
              Social Media Strategy - A blend of Right Communication with
              Selecting the Niche in a Crowded Space
            </div>
            <div className="flex text-base bg-green-300  items-start justify-start border mt-5 border-white/60 py-2 px-7 rounded-full">
              Read article
            </div>
          </div>
        </motion.div>
        <div className="flex items-center flex-wrap w-full">
          {[1, 1, 1, 1].map((post) => (
            <motion.div
              className="w-1/2 h-[450px] p-2"
              initial={{
                opacity: 0,
                scale: 0.9,
              }}
              transition={{
                duration: 0.3,
                delay: 0.3,
                type: "tween",
              }}
              whileInView={{
                opacity: 1,
                scale: 1,
              }}
            >
              <div
                className="w-full h-full relative flex items-start py-10 px-14 justify-end flex-col"
                style={{
                  background: `linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%,  rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 1) 100%), url(https://images.pexels.com/photos/267350/pexels-photo-267350.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2) center/cover no-repeat`,
                }}
              >
                <div className="text-xl font-bold text-white relative z-10">
                  Social Media Strategy - A blend of Right Communication with
                  Selecting the Niche in a Crowded Space
                </div>
                <div className="flex text-base bg-green-300  items-start justify-start border mt-5 border-white/60 py-2 px-7 rounded-full">
                  Read article
                </div>
              </div>
            </motion.div>
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default Blogs;
