import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  About,
  AddensureBlogs,
  BlogCreation,
  BlogDetail,
  BlogEdit,
  Carrer,
  Contact,
  Home,
  NotFound,
  Services,
  ThankYou,
} from "./Links";

export default function RouteConfig() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<NotFound />} />

        <Route path="/in/home/admin">
          <Route
            path="blogs"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <AddensureBlogs />
              </Suspense>
            }
          />
          <Route
            path="blogs/blogedit/:id"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <BlogEdit />
              </Suspense>
            }
          />
          <Route
            path="blogCreations"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <BlogCreation />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="/thankYou"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ThankYou />
            </Suspense>
          }
        />

        <Route
          path="/in/contactus"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Contact />
            </Suspense>
          }
        />

        <Route
          path="/in/services/:serviceType"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Services />
            </Suspense>
          }
        />

        <Route
          path="/in/post/:post"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BlogDetail />
            </Suspense>
          }
        />

        <Route
          path="/in/aboutus"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <About />
            </Suspense>
          }
        />

        <Route
          path="/in/careers"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Carrer />
            </Suspense>
          }
        />

        <Route
          path="/"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Home />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}
